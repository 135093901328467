.carouselHome .slick-dots li button:before {
  color: #fff;
  font-size: 10px;
}
.carouselHome .slick-dots li.slick-active button:before {
  color: #fff;
}
.carouselHome .slick-prev:before,
.carouselHome .slick-next:before {
  font-size: 20px;
}

.carouselHome .slick-dots {
  margin: 0;
  position: absolute;
  bottom: 0.5rem;
}

@media (min-width: 576px) {
  .carouselHome .slick-dots li button:before {
    font-size: 20px;
  }
  .carouselHome .slick-prev:before,
  .carouselHome .slick-next:before {
    font-size: 40px;
  }
  .carouselHome .slick-dots {
    bottom: 1.5rem;
  }
}
