.alert-is-new {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  text-transform: uppercase;
  line-height: 1 !important;
  padding: 0 0.9rem !important;
}
.img-container img {
  max-height: 100%;
}
.carousel-prod .img-container img{
  max-height: 200px;
}

.carousel-prod .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
/* .carousel-nav {
  display: flex;
} */
.carousel-nav .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  height: 60px;
}
.carousel-nav .slick-prev {
  left: 0;
}
.carousel-nav .slick-next {
  right: 0;
}
.carousel-nav .slick-prev:before,
.carousel-nav .slick-next:before {
  color: #e81919;
}

.carousel-nav svg {
  font-size: 2.5rem;
}
@media (min-width: 576px) {
  .carousel-nav .img-container img{
    max-width: 150px;
  }
  .carousel-prod .img-container img{
    max-width: 500px;
    max-height: 500px;
  }
  .carousel-containter {
    padding: 0 20px;
  }
  
  .carousel-containter div:active,
  .carousel-containter div:focus,
  .carousel-containter img:active,
  .carousel-containter img:focus {
    outline:none;
  }
  /* .carousel-nav .img-container {
    height: 100px;
  } */
  .alert-is-new {
    top: 1rem;
    right: 2rem;
    line-height: 1.5 !important;
  }
  .carousel-prod .slick-track {
    height: 510px;
  }
  .carousel-prod .img-container {
    height: 500px;
  }

  .carousel-nav svg {
    font-size: 3rem;
  }
}

